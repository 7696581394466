import React from "react";
import "./Footer.css";

import classNames from "classnames";
import { NavLink } from "react-router-dom";

import useWindowSize from "../../hooks/useWindowSize";

import Logo from "../../files/footer__logo.png";
import LogoMob from "../../files/footer__logo_mob.png";
import MailIcon from "../../files/mail-icon.svg";
import PhoneIcon from "../../files/phone-icon.svg";

export default function Footer({ className, ...otherProps }) {
  const [windowWidth] = useWindowSize();

  return (
    <div
      className={classNames("block footer convex", className)}
      {...otherProps}
    >
      <div className="footer__content block__content_column">
        <nav className="footer__nav">
          <ul className="footer__nav-col">
            <li className="footer__nav-element">
              <NavLink
                to="/offer"
                className="button footer__button"
                activeClassName="footer__button_selected"
              >
                <div className="gradient-border"></div>
                <p className="button__text">Предложение</p>
              </NavLink>
            </li>

            <li className="footer__nav-element">
              <NavLink
                to="/blocks"
                className="button footer__button"
                activeClassName="footer__button_selected"
              >
                <div className="gradient-border"></div>
                <p className="button__text">Блоки</p>
              </NavLink>
            </li>

            <li className="footer__nav-element">
              <NavLink
                to="/order"
                className="button footer__button"
                activeClassName="footer__button_selected"
              >
                <div className="gradient-border"></div>
                <p className="button__text">Заказать сайт</p>
              </NavLink>
            </li>
          </ul>

          <button
            className="button convex footer__scroll-top"
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            <div className="gradient-border"></div>

            <svg
              width="20"
              height="12"
              viewBox="0 0 20 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g filter="url(#filter0_i)">
                <path
                  d="M18 10L10 2L2 10"
                  stroke="#70A0FF"
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <filter
                  id="filter0_i"
                  x="0"
                  y="0"
                  width="20"
                  height="13"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                  />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="1" />
                  <feGaussianBlur stdDeviation="1" />
                  <feComposite
                    in2="hardAlpha"
                    operator="arithmetic"
                    k2="-1"
                    k3="1"
                  />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0.18 0 0 0 0 0.45 0 0 0 0.45 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="shape"
                    result="effect1_innerShadow"
                  />
                </filter>
              </defs>
            </svg>
            наверх
          </button>
        </nav>

        <div className="footer__logo-wrapper">
          {
            windowWidth > 850 ? (
              <img alt="logo" className="footer__logo" src={Logo} />
            ) : (
              <img alt="logo" className="footer__logo_mob" src={LogoMob} />
            )
          }
        </div>

        <div className="footer__contacts">
          <p className="footer__contact">fast-sites@yandex.ru</p>

          <p className="footer__contact">+7 904 711 46 46</p>
        </div>
      </div>
    </div>
  );
}
