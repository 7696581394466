import React, { useState, useEffect, useRef } from "react";
import "./Input.css";

import classNames from "classnames";
import { useSpring, useTransition, animated } from "react-spring";

var inputVerificators = require("./input-verificators.js");

export function verificateInput(
  inputType,
  inputValue,
  minLength,
  maxLength,
  regexp,
  errorText
) {
  if (inputType === "email") {
    return inputVerificators.email(inputValue);
  }
  if (inputType === "password") {
    return inputVerificators.password(inputValue, minLength, maxLength, regexp);
  }
  if (inputType === "tel") {
    return inputVerificators.tel(inputValue);
  } else {
    return inputVerificators.text(
      inputValue,
      minLength,
      maxLength,
      regexp,
      errorText
    );
  }
}

export default function Input({
  value,
  className,
  className_wrapper,
  className_errorText,
  type,
  name,
  placeholder,
  handleInput,
  minLength,
  maxLength,
  regexp,
  displayErrorProp,
  errorText = "Ошибка в поле",
  ...otherProps
}) {
  const [firstRender, setFirstRender] = useState(true);
  const [error, setError] = useState(false);
  const [displayError, setDisplayError] = useState(false);
  const inputWrapRef = useRef();
  const [inputHeight, setInputHeight] = useState();

  useEffect(() => {
    setInputHeight(inputWrapRef.current.scrollHeight);
  });

  useEffect(() => {
    setFirstRender(false);
  }, [firstRender]);

  useEffect(() => {
    if (displayErrorProp) {
      setError(
        verificateInput(type, value, minLength, maxLength, regexp, errorText)
      );
    }
  }, [displayErrorProp]);

  function handleChange(e) {
    var inputType = e.target.type;
    var inputValue = e.target.value;

    var inputError = verificateInput(
      inputType,
      inputValue,
      minLength,
      maxLength,
      regexp,
      errorText
    );

    setError(inputError);

    handleInput(e, inputError);
  }

  function handleBlur(e) {
    var inputType = e.target.type;
    var inputValue = e.target.value;

    var inputError = verificateInput(
      inputType,
      inputValue,
      minLength,
      maxLength,
      regexp,
      errorText
    );

    setError(inputError);

    setDisplayError(true);
  }

  const errorTextAnimation = useTransition(
    (displayError || displayErrorProp) && error,
    null,
    {
      from: { opacity: 0 },
      enter: { opacity: 1 },
      leave: { opacity: 0 }
    }
  );

  const inputWrapAnimation = useSpring(
    { height: firstRender ? "auto" : inputHeight },
  );

  return (
    <animated.div
      style={inputWrapAnimation}
      className={classNames("input-wrapper", className_wrapper)}
    >
      <div
        ref={inputWrapRef}
        className={classNames("input-wrapper__inside", className_wrapper)}
      >
        {type === "textarea" ? (
          <textarea
            className={classNames(
              "input input_textarea text_body concavity",
              {
                input_error: (displayError || displayErrorProp) && error,
              },
              className
            )}
            type={type}
            name={name}
            placeholder={placeholder}
            value={value}
            onChange={(e) => handleChange(e)}
            onBlur={handleBlur}
            {...otherProps}
          ></textarea>
        ) : (
          <input
            className={classNames(
              "input text_body concavity",
              {
                input_error: (displayError || displayErrorProp) && error,
              },
              className
            )}
            type={type}
            name={name}
            placeholder={placeholder}
            value={value}
            onChange={(e) => handleChange(e)}
            onBlur={handleBlur}
            {...otherProps}
          ></input>
        )}

        {errorTextAnimation.map(
          ({ item, key, props }) =>
            item && (
              <animated.div
                key={key}
                style={props}
                className="input__error-wrapper"
              >
                <p
                  className={classNames(
                    "text_body input__error-text",
                    className_errorText
                  )}
                >
                  {errorText}
                </p>
              </animated.div>
            )
        )}
      </div>
    </animated.div>
  );
}
