import React, {
  useState,
  useImperativeHandle,
  useRef,
  useEffect,
  forwardRef,
} from "react";
import "./SGrid__Block.css";

import classNames from "classnames";
import { useSpring, animated } from "react-spring";

export function SGridBlockOpened({
  refCard,
  show,
  className,
  children,
  onClick,
  powerEfficientMode,
  animationInProcess,
  animationMode,
  ...otherProps
}) {
  const blockRef = useRef();
  useEffect(() => {
    if (blockRef.current) {
      if (refCard) {
        show
          ? (blockRef.current.style.display = "flex")
          : (blockRef.current.style.display = "none");
      } else {
        if (!show) {
          blockRef.current.style.position = "absolute";
          blockRef.current.style.opacity = 0;
          setTimeout(() => {
            blockRef.current &&
            (blockRef.current.style.display = "none")
          }, 600);
        }
  
        if (!powerEfficientMode && show) {
          setTimeout(() => {
            blockRef.current &&
            (blockRef.current.style.display = "flex")
            setTimeout(() => {
              blockRef.current &&
              (blockRef.current.style.opacity = 1)
            }, 10);
          }, 400);
        }
      }
    }
  }, [show]);

  useEffect(() => {
    if (blockRef.current && animationMode === "css" && show) {
      setTimeout(() => {
        blockRef.current.style.display = "flex";
        setTimeout(() => {
          blockRef.current &&
          (blockRef.current.style.opacity = 1)
        }, 10);
      }, 800);
    }
  }, [show]);

  // useEffect(() => {
  //   if (blockRef.current && powerEfficientMode && show && animationInProcess === false) {
  //     console.log(2);
  //     setTimeout(() => {
  //       blockRef.current &&
  //       (blockRef.current.style.display = "flex");
  //       setTimeout(() => {
  //         blockRef.current &&
  //         (blockRef.current.style.opacity = 1)
  //       }, 50);
  //     }, 800);
  //   }

  // }, [animationInProcess]);

  return (
    <div
      ref={blockRef}
      className={classNames(
        "s-grid-block__content s-grid-block__content_opened",
        className
      )}
      {...otherProps}
    >
      {children}
    </div>
  );
}

export function SGridBlockClosed({
  refCard,
  show,
  className,
  children,
  onClick,
  powerEfficientMode,
  animationInProcess,
  animationMode,
  ...otherProps
}) {
  const blockRef = useRef();

  useEffect(() => {
    if (refCard) {
      show
        ? (blockRef.current.style.display = "flex")
        : (blockRef.current.style.display = "none");
    } else {
      if (!show) {
        blockRef.current.style.opacity = 0;
        setTimeout(() => {
          blockRef.current &&
          (blockRef.current.style.display = "none")
        }, 600);
      }

      if (!powerEfficientMode && show) {
        setTimeout(() => {
          blockRef.current.style.display = "flex";
          setTimeout(() => {
            blockRef.current &&
            (blockRef.current.style.opacity = 1)
          }, 10);
        }, 400);
      }
    }
  }, [show]);

  useEffect(() => {
    if (blockRef.current && animationMode === "css" && show) {
      setTimeout(() => {
        blockRef.current &&
        (blockRef.current.style.display = "flex")
        setTimeout(() => {
          blockRef.current &&
          (blockRef.current.style.opacity = 1)
        }, 50);
      }, 800);
    }
  }, [show]);

  // useEffect(() => {
  //   if (blockRef.current && powerEfficientMode && show && animationInProcess === false) {
  //     console.log(2);
  //     setTimeout(() => {
  //       blockRef.current &&
  //       (blockRef.current.style.display = "flex");
  //       setTimeout(() => {
  //         blockRef.current &&
  //         (blockRef.current.style.opacity = 1)
  //       }, 50);
  //     }, 800);
  //   }

  // }, [animationInProcess]);

  return (
    <div
      ref={blockRef}
      className={classNames(
        "s-grid-block__content s-grid-block__content_closed",
        className
      )}
      onClick={onClick}
      {...otherProps}
    >
      {children}
    </div>
  );
}

export default function SGridBlock(
  {
    cardId,
    className,
    children,
    animate = {},
    refBlock = false,
    parentWidth,
    refCardOpened,
    visibleCardOpened,
    handleRefCardState,
    widthOpened,
    widthClosed,
    handleCardAnimationStatus,
    ...otherProps
  },
  ref
) {
  const [cardStyles, setCardStyles] = useState();
  const [animationSpring, setAnimationSpring] = useSpring(() => ({}));
  useEffect(() => {
    if (animate.bounds) {
      if (animate.animate === true) {
        setAnimationSpring({
          to: {
            width: animate.bounds.width,
            height: animate.bounds.height,
            transform:
              "translate(" +
              animate.bounds.offsetLeft +
              "px, " +
              animate.bounds.offsetTop +
              "px)",
          },
          onStart: () => handleCardAnimationStatus(cardId, true),
          onRest: () => handleCardAnimationStatus(cardId, false),
        });
      }
      if (animate.animate === "css"){
        setCardStyles({
          width: animate.bounds.width,
          height: animate.bounds.height,
          transform:
            "translate(" +
            animate.bounds.offsetLeft +
            "px, " +
            animate.bounds.offsetTop +
            "px)",
            transition: 'all .5s'
        });
      }
      else {
        setCardStyles({
          width: animate.bounds.width,
          height: animate.bounds.height,
          transform:
            "translate(" +
            animate.bounds.offsetLeft +
            "px, " +
            animate.bounds.offsetTop +
            "px)"
        });
      }
    }
  }, [animate.bounds]);

  const cardRef = useRef(null);

  useImperativeHandle(ref, () => ({
    measure: (powerEfficientMode, throttleTime) => {
      // if (powerEfficientMode) {
      //   let offsetTop = cardRef.current.offsetTop;
      //   let offsetLeft = cardRef.current.offsetLeft;

      //   let bounds = {
      //     offsetTop: offsetTop,
      //     offsetLeft: offsetLeft,
      //   };

      //   handleRefCardState(cardId, refCardOpened, bounds);

      //   setTimeout(() => {
      //     let width = cardRef.current.clientWidth;
      //     let height = cardRef.current.clientHeight;

      //     let bounds = {
      //       width: width,
      //       height: height,
      //     };

      //     handleRefCardState(cardId, refCardOpened, bounds);
      //   }, 300);
      // } else {
      //   let offsetTop = cardRef.current.offsetTop;
      //   let offsetLeft = cardRef.current.offsetLeft;
      //   let width = cardRef.current.clientWidth;
      //   let height = cardRef.current.clientHeight;

      //   let bounds = {
      //     offsetTop: offsetTop,
      //     offsetLeft: offsetLeft,
      //     width: width,
      //     height: height,
      //   };

      //   handleRefCardState(cardId, refCardOpened, bounds);
      // }

      setTimeout(() => {
        if (cardRef.current) {
          let offsetTop = cardRef.current.offsetTop;
          let offsetLeft = cardRef.current.offsetLeft;
          let width = cardRef.current.clientWidth;
          let height = cardRef.current.clientHeight;
  
          let bounds = {
            offsetTop: offsetTop,
            offsetLeft: offsetLeft,
            width: width,
            height: height,
          };
  
          handleRefCardState(cardId, refCardOpened, bounds);
        }
      }, throttleTime);
    },
  }));

  return (
    <animated.div
      style={animate.animate === true && !refBlock ? animationSpring : cardStyles}
      ref={cardRef}
      className={classNames(
        "s-grid-block",
        className,
        refBlock ? "s-grid-block_ref" : "s-grid-block_visible",
        refCardOpened ? "s-grid-block_ref-opened" : "s-grid-block_ref-closed"
      )}
      {...otherProps}
    >
      <div
        className={classNames(
          "s-grid-block__inside",
          (!refBlock && visibleCardOpened) || (refBlock && refCardOpened)
            ? "s-grid-block__inside_opened"
            : "s-grid-block__inside_closed"
        )}
      >
        {children}
      </div>
    </animated.div>
  );
}

// eslint-disable-next-line
SGridBlock = forwardRef(SGridBlock);
