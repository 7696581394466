import React from "react";
import "./Header.css";

import { NavLink } from "react-router-dom";
import classNames from "classnames";

import MobileMenu from "./mobile-menu/mobile-menu";
import Button from "../Button/Button";

import Logo from "../../files/header__logo.svg";
import LogoMobile from "../../files/header-mobile__logo.png";
import MailIcon from "../../files/mail-icon.svg";
import PhoneIcon from "../../files/phone-icon.svg";
import MenuIcon from "../../files/menu-icon.png";
import CloseIcon from "../../files/close-icon.png";

export default function Header({
  className,
  mobMenuOpened,
  handleMobileMenu,
  ...otherProps
}) {
  return (
    <>
      <MobileMenu opened={mobMenuOpened} />

      <div
        className={classNames("header header_pc", className)}
        {...otherProps}
      >
        <div className="header__content">
          <div className="header__logo-wrapper">
            {/* <img src={Logo} /> */}

            <svg
              width="62"
              height="72"
              viewBox="0 0 62 72"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0)">
                <path
                  d="M30.386 36.3115L47.8918 26.3389H47.8824L30.386 36.3052V36.3115Z"
                  fill="black"
                />
                <path
                  d="M0 18.4922V18.5651L0.0605679 18.528L0 18.4922Z"
                  fill="black"
                />
                <path
                  d="M30.3859 50.7138V50.531L5.91921 36.4058V29.2214L30.3859 43.3467V36.3055L0 18.5479V54.3556L30.3859 72.0004V50.7138ZM24.0718 54.2804V61.2798L5.91921 50.7807V43.6684L24.0718 54.2804Z"
                  fill="#70A0FF"
                  fill-opacity="0.85"
                />
                <path
                  d="M30.386 36.3114V43.3464V43.3526L55.1733 28.9683L61.5606 25.2617V25.2555V18.5527L47.8919 26.3388L30.386 36.3114Z"
                  fill="#BDD6FF"
                  fill-opacity="0.85"
                />
                <path
                  d="M30.386 36.3115L47.8918 26.3389H47.8824L30.386 36.3052V36.3115Z"
                  fill="black"
                />
                <path
                  d="M0 18.4922V18.5651L0.0605679 18.528L0 18.4922Z"
                  fill="black"
                />
                <path
                  d="M30.3859 50.7138V50.531L5.91921 36.4058V29.2214L30.3859 43.3467V36.3055L0 18.5479V54.3556L30.3859 72.0004V50.7138ZM24.0718 54.2804V61.2798L5.91921 50.7807V43.6684L24.0718 54.2804Z"
                  fill="#70A0FF"
                  fill-opacity="0.85"
                />
                <path
                  d="M61.5606 54.0351V32.7256L61.2503 32.9032L30.386 50.5041L30.4006 71.9787L61.5606 54.0351Z"
                  fill="#BDD6FF"
                  fill-opacity="0.85"
                />
                <path
                  d="M30.386 36.3114V43.3464V43.3526L55.1733 28.9683L61.5606 25.2617V25.2555V18.5527L47.8919 26.3388L30.386 36.3114Z"
                  fill="#BDD6FF"
                  fill-opacity="0.85"
                />
                <path
                  d="M30.3858 43.3468L5.91919 29.2216V36.3056V36.4059L30.3858 50.5311V50.7139L60.8009 33.1172L61.5604 32.7537L55.1732 28.9688L30.3858 43.3531V43.3468Z"
                  fill="white"
                  fill-opacity="0.5"
                />
                <path
                  d="M30.3859 36.305L47.8823 26.3387L61.5605 18.5474L30.3859 0L0.0459664 18.5192L0 18.5474L30.3859 36.305Z"
                  fill="white"
                  fill-opacity="0.5"
                />
                <path
                  d="M17.4464 57.4579L5.95483 50.7719V43.668L24.028 54.3238V61.3232L17.4464 57.4579Z"
                  fill="white"
                  fill-opacity="0.5"
                />
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect width="61.5605" height="72" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>

          <nav className="header__nav" {...otherProps}>
            <ul className="header__nav-row">
              <li className="header__main-nav-element">
                <NavLink
                  to="/offer"
                  className="button header__button"
                  activeClassName="button_active"
                  isActive={(match, location) => {
                    if (
                      location.pathname === "/" ||
                      location.pathname === "/offer"
                    ) {
                      return true;
                    }
                  }}
                >
                  <div className="gradient-border"></div>
                  <p className="button__text">Предложение</p>
                </NavLink>
              </li>

              <li className="header__main-nav-element">
                <NavLink
                  to="/blocks"
                  className="button header__button"
                  activeClassName="button_active"
                >
                  <div className="gradient-border"></div>
                  <p className="button__text">Блоки</p>
                </NavLink>
              </li>

              <li className="header__main-nav-element">
                <NavLink
                  to="/order"
                  className="button header__button"
                  activeClassName="button_active"
                >
                  <div className="gradient-border"></div>
                  <p className="button__text">Заказать сайт</p>
                </NavLink>
              </li>
            </ul>

            {/* <Switch>
              <Route path="/(offer|)/">
                <ul className="header__nav-row">
                  <li className="header__sub-nav-element">
                    <NavLink to="/offer#intro" className="header__sub-link">
                      Главная
                    </NavLink>
                  </li>

                  <li className="header__sub-nav-element">
                    <NavLink to="/offer#algorithm" className="header__sub-link">
                      Как это работает
                    </NavLink>
                  </li>

                  <li className="header__sub-nav-element">
                    <NavLink
                      to="/offer#additional"
                      className="header__sub-link"
                    >
                      Дополнительные услуги
                    </NavLink>
                  </li>
                </ul>
              </Route>

              <Route path="/blocks">
                <ul className="header__nav-row">
                  <li className="header__sub-nav-element">
                    <NavLink to="/blocks#base" className="header__sub-link">
                      Базовый комплект
                    </NavLink>
                  </li>

                  <li className="header__sub-nav-element">
                    <NavLink
                      to="/blocks#additional"
                      className="header__sub-link"
                    >
                      Дополнительные блоки
                    </NavLink>
                  </li>

                  <li className="header__sub-nav-element">
                    <NavLink to="/blocks#examples" className="header__sub-link">
                      Примеры работ
                    </NavLink>
                  </li>
                </ul>
              </Route>
            </Switch> */}
          </nav>

          <div className="header__contacts">
            <div className="header__contacts-row">
              <img className="header__contact-icon" src={MailIcon} />
              <p className="header__contact">fast-sites@yandex.ru</p>
            </div>

            <div className="header__contacts-row">
              <img className="header__contact-icon" src={PhoneIcon} />
              <p className="header__contact">+7 904 711 46 46</p>
            </div>
          </div>
        </div>
      </div>

      <div
        className={classNames("header header-mobile", classNames)}
        style={{ position: mobMenuOpened && "fixed" }}
        {...otherProps}
      >
        <div className="header-mobile__logo-wrapper">
          <img className="header-mobile__logo" src={LogoMobile} />
        </div>

        <div className="header-mobile__menu-button-wrapper">
          <div className="gradient-border"></div>

          <Button
            className="header-mobile__menu-button"
            onClick={() => handleMobileMenu(!mobMenuOpened)}
          >
            {mobMenuOpened ? (
              <img className="header-mobile__close-icon" src={CloseIcon} />
            ) : (
              <img className="header-mobile__menu-icon" src={MenuIcon} />
            )}
          </Button>
          {/* <button
            className="header-mobile__menu-button button convex"
            onClick={() => handleMobileMenu(!mobMenuOpened)}
          >
            {mobMenuOpened ? (
              <img
                className="header-mobile__close-icon"
                src={CloseIcon}
              />
            ) : (
              <img
                className="header-mobile__menu-icon"
                src={MenuIcon}
              />
            )}
          </button> */}
        </div>
      </div>
    </>
  );
}
