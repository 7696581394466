import React from "react";
import "./BlocksPage.css";

import SpringGrid from "../../components/SpringGrid/SpringGrid";

import CloseIconBlue from "../../files/card-close-icon_blue.png";
import OpenIconBlue from '../../files/card-open-icon_blue.png';
import CloseIconPink from "../../files/card-close-icon_pink.png";
import OpenIconPink from '../../files/card-open-icon_pink.png';
import Intro1 from "../../files/blocks__intro-1.png";
import Intro2 from "../../files/blocks__intro-2.png";
import Intro3 from "../../files/blocks__intro-3.png";
import Intro4 from "../../files/blocks__intro-4.png";
import Offers1 from "../../files/blocks__offers-1.png";
import Offers2 from "../../files/blocks__offers-2.png";
import Offers3 from "../../files/blocks__offers-3.png";
import Offers4 from "../../files/blocks__offers-4.png";
import Offers5 from "../../files/blocks__offers-5.png";
import Offers6 from "../../files/blocks__offers-6.png";
import Offers7 from "../../files/blocks__offers-7.png";
import Offers8 from "../../files/blocks__offers-8.png";
import Form1 from '../../files/blocks__form-1.png';
import Price1 from '../../files/blocks__price-1.png';
import Price2 from '../../files/blocks__price-2.png';
import Price3 from '../../files/blocks__price-3.png';
import Reviews1 from '../../files/blocks__reviews-1.png';
import Reviews2 from '../../files/blocks__reviews-2.png';
import Reviews3 from '../../files/blocks__reviews-3.png';
import Contacts1 from '../../files/blocks__contacts-1.png';
import Contacts2 from '../../files/blocks__contacts-2.png';
import Contacts3 from '../../files/blocks__contacts-3.png';
import Contacts4 from '../../files/blocks__contacts-4.png';
import Contacts5 from '../../files/blocks__contacts-5.png';
import Page1 from '../../files/blocks__page-1.png';
import Page1Closed from '../../files/blocks__page-1_closed.png';
import Page2 from '../../files/blocks__page-2.png';
import Page3 from '../../files/blocks__page-3.png';
import Team1 from '../../files/blocks__team-1.png';
import Team2 from '../../files/blocks__team-2.png';
import Gallery1 from '../../files/blocks__gallery-1.png';
import Gallery2 from '../../files/blocks__gallery-2.png';

export default function BlocksPage() {
  return (
    <div className="block">
      <div className="block__content_column">
        <h1>Система блоков</h1>

        <p className="text_body blocks-pg__text-body">
        Вы сами определяете, каким будет ваш сайт.<br />Выбирайте блоки и добавляйте их к базовому комплекту.
        </p>
      </div>

      <div className="block__content_column blocks-pg__block">
        <h3 className="blocks-pg__h3">Базовый комплект</h3>

        <p className="text_body">
          Вот стартовый набор блоков. <br />
          Сайт в такой конфигурации стоит 9000₽.
        </p>

        <SpringGrid
          className="blocks-pg__s-grid"
        >
          <div power_efficient="true">
            <div content_opened="true">
              <button
                className="blocks-pg__s-grid-close-btn"
                close_button="true"
              >
                <img className="blocks-pg__close-icon" src={CloseIconBlue} />
              </button>

              <div className="blocks-pg__card">
                <h4 className="blocks-pg__card-h4">Интро</h4>

                <p className="text_mini-body blocks-pg__text_mini-body">
                Главная страница, шапка сайта с кратким описанием бизнеса.
                </p>

                <div className="blocks-pg__card-examples blocks-pg__card-examples_opened">
                  <div className="blocks-pg_img-row">
                    <img
                      src={Intro1}
                      className="blocks-pg__card-img blocks-pg__card-img_opened convex_smaller"
                    />
                    <img
                      src={Intro2}
                      className="blocks-pg__card-img blocks-pg__card-img_opened convex_smaller"
                    />
                  </div>

                  <div className="blocks-pg_img-row">
                    <img
                      src={Intro3}
                      className="blocks-pg__card-img blocks-pg__card-img_opened convex_smaller"
                    />
                    <img
                      src={Intro4}
                      className="blocks-pg__card-img blocks-pg__card-img_opened convex_smaller"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div content_closed="true" className="blocks-pg__s-grid-closed">
              <button
                className="blocks-pg__s-grid-open-btn"
              >
                <img className="blocks-open-icon" src={OpenIconBlue} />
              </button>
              
              <div className="blocks-pg__card">
                <h4 className="blocks-pg__card-h4">Интро</h4>

                <p className="text_mini-body blocks-pg__text_mini-body">
                Главная страница, шапка сайта с кратким описанием бизнеса.
                </p>

                <div className="blocks-pg__card-examples blocks-pg__card-examples_closed">
                  <img
                    src={Intro1}
                    className="blocks-pg__card-img blocks-pg__card-img_closed convex_smaller"
                  />
                </div>
              </div>
            </div>
          </div>

          <div power_efficient="true">
            <div content_opened="true">
              <button
                className="blocks-pg__s-grid-close-btn"
                close_button="true"
              >
                <img className="blocks-pg__close-icon" src={CloseIconBlue} />
              </button>

              <div className="blocks-pg__card">
                <h4 className="blocks-pg__card-h4">Услуги</h4>

                <p className="text_mini-body blocks-pg__text_mini-body">
                Описание ваших товаров и услуг.
                </p>

                <div className="blocks-pg__card-examples">
                  <div className="blocks-pg_img-row">
                    <img
                      src={Offers1}
                      className="blocks-pg__card-img blocks-pg__card-img_opened convex_smaller"
                    />
                    <img
                      src={Offers2}
                      className="blocks-pg__card-img blocks-pg__card-img_opened convex_smaller"
                    />
                  </div>

                  <div className="blocks-pg_img-row">
                    <img
                      src={Offers3}
                      className="blocks-pg__card-img blocks-pg__card-img_opened convex_smaller"
                    />
                    <img
                      src={Offers4}
                      className="blocks-pg__card-img blocks-pg__card-img_opened convex_smaller"
                    />
                  </div>

                  <div className="blocks-pg_img-row">
                    <img
                      src={Offers5}
                      className="blocks-pg__card-img blocks-pg__card-img_opened convex_smaller"
                    />
                    <img
                      src={Offers6}
                      className="blocks-pg__card-img blocks-pg__card-img_opened convex_smaller"
                    />
                  </div>

                  <div className="blocks-pg_img-row">
                    <img
                      src={Offers7}
                      className="blocks-pg__card-img blocks-pg__card-img_opened convex_smaller"
                    />
                    <img
                      src={Offers8}
                      className="blocks-pg__card-img blocks-pg__card-img_opened convex_smaller"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div content_closed="true" className="blocks-pg__s-grid-closed">
              <button
                className="blocks-pg__s-grid-open-btn"
              >
                <img className="blocks-open-icon" src={OpenIconBlue} />
              </button>
              <div className="blocks-pg__card">
                <h4 className="blocks-pg__card-h4">Услуги</h4>

                <p className="text_mini-body blocks-pg__text_mini-body">
                Описание ваших товаров и услуг.
                </p>

                <div className="blocks-pg__card-examples">
                  <img
                    src={Offers1}
                    className="blocks-pg__card-img blocks-pg__card-img_closed convex_smaller"
                  />
                </div>
              </div>
            </div>
          </div>

          <div power_efficient="true" always_closed="true">
            <div content_opened="true">
              <button
                className="blocks-pg__s-grid-close-btn"
                close_button="true"
              >
                <img className="blocks-pg__close-icon" src={CloseIconBlue} />
              </button>

              <div className="blocks-pg__card">
                <h4 className="blocks-pg__card-h4">Форма заявки</h4>

                <p className="text_mini-body blocks-pg__text_mini-body">
                Генерация лидов без лишних усилий.
                </p>

                <div className="blocks-pg__card-examples">
                  <div className="blocks-pg_img-row">
                    <img
                      src={Form1}
                      className="blocks-pg__card-img blocks-pg__card-img_opened convex_smaller"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div content_closed="true" className="blocks-pg__s-grid-closed">
              <div className="blocks-pg__card">
                <h4 className="blocks-pg__card-h4">Форма заявки</h4>

                <p className="text_mini-body blocks-pg__text_mini-body">
                Генерация лидов без лишних усилий.
                </p>

                <div className="blocks-pg__card-examples">
                  <img
                    src={Form1}
                    className="blocks-pg__card-img blocks-pg__card-img_closed convex_smaller"
                  />
                </div>
              </div>
            </div>
          </div>

          <div power_efficient="true">
            <div content_opened="true">
              <button
                className="blocks-pg__s-grid-close-btn"
                close_button="true"
              >
                <img className="blocks-pg__close-icon" src={CloseIconBlue} />
              </button>

              <div className="blocks-pg__card">
                <h4 className="blocks-pg__card-h4">Контакты</h4>

                <p className="text_mini-body blocks-pg__text_mini-body">
                Оставайтесь на связи с клиентами и помогите им найти вас.
                </p>

                <div className="blocks-pg__card-examples">
                  <div className="blocks-pg_img-row">
                    <img
                      src={Contacts1}
                      className="blocks-pg__card-img blocks-pg__card-img_opened convex_smaller"
                    />
                    <img
                      src={Contacts2}
                      className="blocks-pg__card-img blocks-pg__card-img_opened convex_smaller"
                    />
                  </div>

                  <div className="blocks-pg_img-row">
                    <img
                      src={Contacts3}
                      className="blocks-pg__card-img blocks-pg__card-img_opened convex_smaller"
                    />
                    <img
                      src={Contacts4}
                      className="blocks-pg__card-img blocks-pg__card-img_opened convex_smaller"
                    />
                  </div>

                  <div className="blocks-pg_img-row">
                    <img
                      src={Contacts5}
                      className="blocks-pg__card-img blocks-pg__card-img_opened convex_smaller"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div content_closed="true" className="blocks-pg__s-grid-closed">
              <button
                className="blocks-pg__s-grid-open-btn"
              >
                <img className="blocks-open-icon" src={OpenIconBlue} />
              </button>
              <div className="blocks-pg__card">
                <h4 className="blocks-pg__card-h4">Контакты</h4>

                <p className="text_mini-body blocks-pg__text_mini-body">
                Оставайтесь на связи с клиентами и помогите им найти вас.
                </p>

                <div className="blocks-pg__card-examples">
                  <img
                    src={Contacts1}
                    className="blocks-pg__card-img blocks-pg__card-img_closed convex_smaller"
                  />
                </div>
              </div>
            </div>
          </div>
        </SpringGrid>
      </div>

      <div className="block__content_column blocks-pg__block">
        <h3 className="blocks-pg__h3">Дополнительные блоки</h3>

        <p className="text_body">
        Выбирайте только те, которые вам необходимы.
          <br />
          Цена блоков – от 2000₽ до 5000₽.
        </p>

        <SpringGrid
          className="blocks-pg__s-grid"
        >
          <div power_efficient="true">
            <div content_opened="true">
              <button
                className="blocks-pg__s-grid-close-btn"
                close_button="true"
              >
                <img className="blocks-pg__close-icon" src={CloseIconPink} />
              </button>

              <div className="blocks-pg__card">
                <h4 className="blocks-pg__card-h4 blocks-pg__card-h4_additional">
                Об услугах
                </h4>

                <p className="text_mini-body blocks-pg__text_mini-body">
                Расскажите подробнее о каждом товаре или услуге.
                </p>

                <div className="blocks-pg__card-examples blocks-pg__card-examples_opened">
                  <div className="blocks-pg_img-row">
                    <img
                      src={Page1}
                      className="blocks-pg__card-img blocks-pg__card-img_opened convex_smaller"
                    />
                    <img
                      src={Page2}
                      className="blocks-pg__card-img blocks-pg__card-img_opened convex_smaller"
                    />
                  </div>

                  <div className="blocks-pg_img-row">
                    <img
                      src={Page3}
                      className="blocks-pg__card-img blocks-pg__card-img_opened convex_smaller"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div content_closed="true" className="blocks-pg__s-grid-closed">
              <button
                className="blocks-pg__s-grid-open-btn"
              >
                <img className="blocks-open-icon" src={OpenIconPink} />
              </button>
              <div className="blocks-pg__card">
                <h4 className="blocks-pg__card-h4 blocks-pg__card-h4_additional">
                Об услугах
                </h4>

                <p className="text_mini-body blocks-pg__text_mini-body">
                Расскажите подробнее о каждом товаре или услуге.
                </p>

                <div className="blocks-pg__card-examples blocks-pg__card-examples_closed">
                  <img
                    src={Page1Closed}
                    className="blocks-pg__card-img blocks-pg__card-img_closed convex_smaller"
                  />
                </div>
              </div>
            </div>
          </div>

          <div power_efficient="true">
            <div content_opened="true">
              <button
                className="blocks-pg__s-grid-close-btn"
                close_button="true"
              >
                <img className="blocks-pg__close-icon" src={CloseIconPink} />
              </button>

              <div className="blocks-pg__card">
                <h4 className="blocks-pg__card-h4 blocks-pg__card-h4_additional">
                  Команда
                </h4>

                <p className="text_mini-body blocks-pg__text_mini-body">
                  Расскажите о своём коллективе.
                </p>

                <div className="blocks-pg__card-examples">
                  <div className="blocks-pg_img-row">
                    <img
                      src={Team1}
                      className="blocks-pg__card-img blocks-pg__card-img_opened convex_smaller"
                    />
                    <img
                      src={Team2}
                      className="blocks-pg__card-img blocks-pg__card-img_opened convex_smaller"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div content_closed="true" className="blocks-pg__s-grid-closed">
              <button
                className="blocks-pg__s-grid-open-btn"
              >
                <img className="blocks-open-icon" src={OpenIconPink} />
              </button>
              <div className="blocks-pg__card">
                <h4 className="blocks-pg__card-h4 blocks-pg__card-h4_additional">
                  Команда
                </h4>

                <p className="text_mini-body blocks-pg__text_mini-body">
                  Расскажите о своём коллективе.
                </p>

                <div className="blocks-pg__card-examples">
                  <img
                    src={Team1}
                    className="blocks-pg__card-img blocks-pg__card-img_closed convex_smaller"
                  />
                </div>
              </div>
            </div>
          </div>

          <div power_efficient="true">
            <div content_opened="true">
              <button
                className="blocks-pg__s-grid-close-btn"
                close_button="true"
              >
                <img className="blocks-pg__close-icon" src={CloseIconPink} />
              </button>

              <div className="blocks-pg__card">
                <h4 className="blocks-pg__card-h4 blocks-pg__card-h4_additional">
                  Отзывы
                </h4>

                <p className="text_mini-body blocks-pg__text_mini-body">
                Размещайте отзывы о вашей работе и завоевывайте доверие клиентов.
                </p>

                <div className="blocks-pg__card-examples">
                  <div className="blocks-pg_img-row">
                    <img
                      src={Reviews1}
                      className="blocks-pg__card-img blocks-pg__card-img_opened convex_smaller"
                    />
                    <img
                      src={Reviews2}
                      className="blocks-pg__card-img blocks-pg__card-img_opened convex_smaller"
                    />
                  </div>

                  <div className="blocks-pg_img-row">
                    <img
                      src={Reviews3}
                      className="blocks-pg__card-img blocks-pg__card-img_opened convex_smaller"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div content_closed="true" className="blocks-pg__s-grid-closed">
              <button
                className="blocks-pg__s-grid-open-btn"
              >
                <img className="blocks-open-icon" src={OpenIconPink} />
              </button>
              <div className="blocks-pg__card">
                <h4 className="blocks-pg__card-h4 blocks-pg__card-h4_additional">
                  Отзывы
                </h4>

                <p className="text_mini-body blocks-pg__text_mini-body">
                Размещайте отзывы о вашей работе и завоевывайте доверие клиентов.
                </p>

                <div className="blocks-pg__card-examples">
                  <img
                    src={Reviews1}
                    className="blocks-pg__card-img blocks-pg__card-img_closed convex_smaller"
                  />
                </div>
              </div>
            </div>
          </div>

          <div power_efficient="true">
            <div content_opened="true">
              <button
                className="blocks-pg__s-grid-close-btn"
                close_button="true"
              >
                <img className="blocks-pg__close-icon" src={CloseIconPink} />
              </button>

              <div className="blocks-pg__card">
                <h4 className="blocks-pg__card-h4 blocks-pg__card-h4_additional">
                  Цены
                </h4>

                <p className="text_mini-body blocks-pg__text_mini-body">
                Расскажите, сколько стоят ваши товары и услуги.
                </p>

                <div className="blocks-pg__card-examples">
                  <div className="blocks-pg_img-row">
                    <img
                      src={Price1}
                      className="blocks-pg__card-img blocks-pg__card-img_opened convex_smaller"
                    />
                    <img
                      src={Price2}
                      className="blocks-pg__card-img blocks-pg__card-img_opened convex_smaller"
                    />
                  </div>

                  <div className="blocks-pg_img-row">
                    <img
                      src={Price3}
                      className="blocks-pg__card-img blocks-pg__card-img_opened convex_smaller"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div content_closed="true" className="blocks-pg__s-grid-closed">
              <button
                className="blocks-pg__s-grid-open-btn"
              >
                <img className="blocks-open-icon" src={OpenIconPink} />
              </button>
              <div className="blocks-pg__card">
                <h4 className="blocks-pg__card-h4 blocks-pg__card-h4_additional">
                  Цены
                </h4>

                <p className="text_mini-body blocks-pg__text_mini-body">
                Расскажите, сколько стоят ваши товары и услуги.
                </p>

                <div className="blocks-pg__card-examples">
                  <img
                    src={Price1}
                    className="blocks-pg__card-img blocks-pg__card-img_closed convex_smaller"
                  />
                </div>
              </div>
            </div>
          </div>

          <div power_efficient="true">
            <div content_opened="true">
              <button
                className="blocks-pg__s-grid-close-btn"
                close_button="true"
              >
                <img className="blocks-pg__close-icon" src={CloseIconPink} />
              </button>

              <div className="blocks-pg__card">
                <h4 className="blocks-pg__card-h4 blocks-pg__card-h4_additional">
                  Галерея
                </h4>

                <p className="text_mini-body blocks-pg__text_mini-body">
                Покажите свои продукты или услуги клиенту.
                </p>

                <div className="blocks-pg__card-examples">
                  <div className="blocks-pg_img-row">
                    <img
                      src={Gallery1}
                      className="blocks-pg__card-img blocks-pg__card-img_opened convex_smaller"
                    />
                    <img
                      src={Gallery2}
                      className="blocks-pg__card-img blocks-pg__card-img_opened convex_smaller"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div content_closed="true" className="blocks-pg__s-grid-closed">
              <button
                className="blocks-pg__s-grid-open-btn"
              >
                <img className="blocks-open-icon" src={OpenIconPink} />
              </button>
              <div className="blocks-pg__card">
                <h4 className="blocks-pg__card-h4 blocks-pg__card-h4_additional">
                  Галерея
                </h4>

                <p className="text_mini-body blocks-pg__text_mini-body">
                Покажите свои продукты или услуги клиенту.
                </p>

                <div className="blocks-pg__card-examples">
                  <img
                    src={Gallery1}
                    className="blocks-pg__card-img blocks-pg__card-img_closed convex_smaller"
                  />
                </div>
              </div>
            </div>
          </div>
        </SpringGrid>
      </div>

      {/* <div className="block__content_column blocks-pg__block">
        <h3 className="blocks-pg__h3">примеры работ</h3>

        <p className="text_body">
          Готовые сайты, собранные из наших блоков.
        </p>

        <SpringGrid
          className="blocks-pg__s-grid"
        >
          <div power_efficient="true">
            <div content_opened="true">
              <button
                className="blocks-pg__s-grid-close-btn"
                close_button="true"
              >
                <img className="blocks-pg__close-icon" src={CloseIconBlue} />
              </button>

              <h4>portfolio opened 1</h4>
            </div>

            <div content_closed="true" className="blocks-pg__s-grid-closed">
              <button
                className="blocks-pg__s-grid-open-btn"
              >
                <img className="blocks-open-icon" src={OpenIconBlue} />
              </button>
              <h4>portfolio closed 1</h4>
            </div>
          </div>

          <div power_efficient="true">
            <div content_opened="true">
              <button
                className="blocks-pg__s-grid-close-btn"
                close_button="true"
              >
                <img className="blocks-pg__close-icon" src={CloseIconBlue} />
              </button>

              <h4>portfolio opened 2</h4>
            </div>

            <div content_closed="true" className="blocks-pg__s-grid-closed">
              <button
                className="blocks-pg__s-grid-open-btn"
              >
                <img className="blocks-open-icon" src={OpenIconBlue} />
              </button>
              <h4>portfolio closed 2</h4>
            </div>
          </div>
        </SpringGrid>
      </div> */}
    </div>
  );
}
