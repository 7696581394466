import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import { useTransition, animated } from "react-spring";
import smoothscroll from "smoothscroll-polyfill";
import "./App.css";
import "./variables.css";
import "./fonts/fonts.css";

import OrderSuccess from "./files/order-result__success.png";

import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import OfferPage from "./pages/OfferPage/OfferPage";
import OrderForm from "./pages/OrderForm/OrderForm";
import BlocksPage from "./pages/Blocks/BlocksPage";

smoothscroll.polyfill();

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    // window.scrollTo(0, 0);
    window.scrollTo({ top: 0, behavior: "smooth" })
  }, [pathname]);

  return null;
}

function Pages({ handleMobileMenu, handleOrderStatus }) {
  const location = useLocation();
  const transitions = useTransition(location, (location) => location.pathname, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  useEffect(() => {
    handleMobileMenu(false);
  }, [location, handleMobileMenu]);

  return transitions.map(({ item: location, props, key }) => (
    <animated.div className="page__content" key={key} style={props}>
      <Switch location={location}>
        <Route path="/(offer|)/">
          <OfferPage />
        </Route>

        <Route path="/blocks">
          <BlocksPage />
        </Route>

        <Route path="/order">
          <OrderForm handleOrderStatus={handleOrderStatus} />
        </Route>
      </Switch>
    </animated.div>
  ));
}

function App() {
  const page = useRef();
  const [mobMenuOpened, setMobMenuOpened] = useState(false);
  const handleMobileMenu = useCallback((state) => {
    if (page.current) {
      state
        ? (page.current.style.height = 0)
        : (page.current.style.height = "100%");
    }

    setMobMenuOpened(state);
  }, []);

  const [orderSucces, setOrderSucces] = useState(false);
  const orderSuccessAnimation = useTransition(orderSucces, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const [orderError, setOrderError] = useState(false);
  const errorAnimation = useTransition(orderError, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  function handleOrderStatus(status) {
    if (status === "error") {
      setOrderSucces(false);
      setOrderError(true);
      return;
    }
    if (status === "success") {
      setOrderSucces(true);
      setOrderError(false);
      return;
    } else {
      setOrderSucces(false);
      setOrderError(false);
    }
  }

  return (
    <div id="app" className="app">
      <Router>
        <ScrollToTop handleMobileMenu={handleMobileMenu} />

        <div ref={page} id="page" className="page">
          <Header
            mobMenuOpened={mobMenuOpened}
            handleMobileMenu={handleMobileMenu}
          />

          <Pages
            handleMobileMenu={handleMobileMenu}
            handleOrderStatus={handleOrderStatus}
          />

          {/* <Switch >
            <Route path="/(offer|)/">
              <OfferPage />
            </Route>

            <Route path="/blocks">
              <BlocksPage />
            </Route>

            <Route path="/order">
              <OrderForm />
            </Route>
          </Switch> */}

          <Footer />
        </div>
      </Router>

      {orderSuccessAnimation.map(
        ({ item, key, props }) =>
          item && (
            <animated.div
              key={key}
              style={props}
              className="order-result__popup"
            >
              <div className="order-result__result-card">
                <div className="order-result__succes-icon">
                  <img src={OrderSuccess} />
                </div>

                <p className="text_body order-result__text">
                  Спасибо за заявку! Мы свяжемся с вами в ближайшее время!
                </p>

                <button
                  onClick={handleOrderStatus}
                  className="button convex_smaller order-result__close-popup"
                >
                  <div className="gradient-border"></div>
                  хорошо
                </button>
              </div>
            </animated.div>
          )
      )}

      {errorAnimation.map(
        ({ item, key, props }) =>
          item && (
            <animated.div
              key={key}
              style={props}
              className="order-result__popup"
            >
              <div className="order-result__result-card">
                <p className="order-result__error-title">Упс!</p>

                <p className="text_body order-result__text">
                  Произошла ошибка! Заявка не отправлена из-за неполадок на
                  сервере. Свяжитесь с нами по почте{" "}
                  <span className="order-result__contact">
                    fast-sites@yandex.ru
                  </span>{" "}
                  или телефону{" "}
                  <span className="order-result__contact">
                    +7 904 711 46 46
                  </span>
                </p>

                <button
                  onClick={handleOrderStatus}
                  className="button convex_smaller order-result__close-popup"
                >
                  <div className="gradient-border"></div>
                  хорошо
                </button>
              </div>
            </animated.div>
          )
      )}
    </div>
  );
}

export default App;
