import React from "react";
import "./mobile-menu.css";

import classNames from "classnames";
import { useTransition, animated } from "react-spring";
import { NavLink } from "react-router-dom";

// import Header from '../../Header/Header';

import MailIcon from '../../../files/mail-icon.svg';
import PhoneIcon from '../../../files/phone-icon.svg';

export default function MobileMenu({opened, ...otherProps}) {
  const transitions = useTransition(opened, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { tension: 250 }
  });

  return (
    <>
      {transitions.map(({ item, props, key }) => {
        return (
          item && (
            <animated.div
              key={key}
              style={props}
              className={classNames("m-menu")}
              {...otherProps}
            >
              <nav className="m-menu__nav">
                <ul className="m-menu__nav-col">
                  <li className="m-menu__nav-element">
                    <NavLink
                      to="/offer"
                      className="button m-menu__button"
                      activeClassName="button_active"
                      isActive={(match, location) => {
                        if (
                          location.pathname === "/" ||
                          location.pathname === "/offer"
                        ) {
                          return true;
                        }
                      }}
                    >
                      <div className="gradient-border"></div>
                      <p className="button__text">Предложение</p>
                    </NavLink>
                  </li>

                  <li className="m-menu__nav-element">
                    <NavLink
                      to="/blocks"
                      className="button m-menu__button"
                      activeClassName="button_active"
                    >
                      <div className="gradient-border"></div>
                      <p className="button__text">Блоки</p>
                    </NavLink>
                  </li>

                  <li className="m-menu__nav-element">
                    <NavLink
                      to="/order"
                      className="button m-menu__button"
                      activeClassName="button_active"
                    >
                      <div className="gradient-border"></div>
                      <p className="button__text">Заказать сайт</p>
                    </NavLink>
                  </li>
                </ul>
              </nav>

              <div className="m-menu__contacts">
                <div className="m-menu__contacts-row">
                  <img className="m-menu__contact-icon" src={MailIcon} />
                  <p className="m-menu__contact">fast-sites@yandex.ru</p>
                </div>

                <div className="m-menu__contacts-row">
                  <img className="m-menu__contact-icon" src={PhoneIcon} />
                  <p className="m-menu__contact">+7 904 711 46 46</p>
                </div>
              </div>
            </animated.div>
          )
        )
      })}
    </>
  );
}
