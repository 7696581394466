import React, { useState } from 'react';
import './Form.css';

import classNames from "classnames";

import Input from "../../components/Input/Input";

const axios = require("axios");

export default function Form({ className, handleOrderStatus }) {
  const [name, setName] = useState({ value: "", error: true });
  const [company, setCompany] = useState({ value: "", error: true });
  const [phone, setPhone] = useState({ value: "", error: true });
  const [message, setMessage] = useState({ value: "", error: false });
  const [email, setEmail] = useState({ value: "", error: false });
  const [displayError, setDisplayError] = useState(false);

  function dropInputs() {
    setName({ value: "", error: true });
    setCompany({ value: "", error: true });
    setPhone({ value: "", error: true });
    setMessage({ value: "", error: true });
    setEmail({ value: "", error: true });
    setDisplayError(false);
  }

  function handleInput(e, error) {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const targetName = target.name;

    const setValue = {
      name: (value) =>
        setName({
          value: value,
          error: error.error,
        }),
      company: (value) =>
        setCompany({
          value: value,
          error: error.error,
        }),
      phone: (value) =>
        setPhone({
          value: value,
          error: error.error,
        }),
      email: (value) =>
        setEmail({
          value: value,
          error: error.error,
        }),
      message: (value) =>
        setMessage({
          value: value,
          error: error.error,
        }),
    };
    setValue[targetName](value);
  }

  function order(e) {
    e.preventDefault();

    if (name.error || email.error || phone.error) {
      setDisplayError(true);
      return;
    }

    let orderData = {
      name: name.value,
      company: company.value,
      phone: phone.value,
      email: email.value,
      message: message.value,
    };

    axios
      .post("order", orderData)
      .then((response) => {
        handleOrderStatus('success')
        dropInputs();
        window.ym(64767643,'reachGoal','success form submit');
      })
      .catch((error) => {
        handleOrderStatus('error')
        console.log(error);
      });
  }

  return (
    <form className="form" onSubmit={(e) => order(e)}>
      <Input
        value={name.value}
        className="form__input"
        className_wrapper="form__input-wrapper"
        className_errorText="form__error-text"
        name="name"
        placeholder="Имя"
        handleInput={handleInput}
        minLength="3"
        maxLength="35"
        displayErrorProp={displayError}
        errorText="3-35 символов, только буквы и '-'"
      />

      <Input
        value={phone.value}
        className="form__input"
        className_wrapper="form__input-wrapper"
        className_errorText="form__error-text"
        type="tel"
        name="phone"
        placeholder="Телефон"
        handleInput={handleInput}
        displayErrorProp={displayError}
      />

      <Input
        value={email.value}
        className="form__input"
        className_wrapper="form__input-wrapper"
        className_errorText="form__error-text"
        type="email"
        name="email"
        placeholder="Почта"
        handleInput={handleInput}
        displayErrorProp={displayError}
      />

      <Input
        value={company.value}
        className="form__input"
        className_wrapper="form__input-wrapper"
        className_errorText="form__error-text"
        name="company"
        placeholder="Компания"
        handleInput={handleInput}
        displayErrorProp={displayError}
      />

      <Input
        value={message.value}
        className="form__input form__input_textarea"
        className_wrapper="form__input-wrapper"
        className_errorText="form__error-text"
        type="textarea"
        name="message"
        placeholder="Сообщение"
        handleInput={handleInput}
        displayErrorProp={displayError}
      />

      <div className="form__bottom">
        <button className="button form__button">Заказать</button>
      </div>
    </form>
  );
}
