import React from 'react';
import './OrderForm.css';

import Form from '../../components/Form/Form';

export default function OrderForm({ handleOrderStatus }) {
  return (
    <div className="block">
      <div className="block__content_column">
        <h2 className="order-form__h2">Заказать сайт</h2>

        <p className="text_body order-form__text_body">
        Оставьте заявку. Мы свяжемся с вами в течение суток.
        </p>

        <Form handleOrderStatus={handleOrderStatus} />
      </div>
    </div>
  )
}