import React from "react";
import "./OfferPage.css";

import { NavLink } from "react-router-dom";

import useWindowSize from "../../hooks/useWindowSize";

import IntroPhotoPc from "../../files/intro_pc.png";
import IntroPhotoMob from "../../files/intro_mob.png";
import ClueLine from "../../files/clue-line.png";
import ClueLineMob from "../../files/clue-line_mob.png";
import AlgorithmStep1 from "../../files/algorithm__step-1.png";
import AlgorithmStep2 from "../../files/algorithm__step-2.png";
import AlgorithmStep3 from "../../files/algorithm__step-3.png";
import AlgorithmStep4 from "../../files/algorithm__step-4.png";
import AlgorithmStep5 from "../../files/algorithm__step-5.png";
import AlgorithmStep6 from "../../files/algorithm__step-6.png";
import AlgorithmStep1Mob from "../../files/algorithm__step-1_mob.png";
import AlgorithmStep2Mob from "../../files/algorithm__step-2_mob.png";
import AlgorithmStep3Mob from "../../files/algorithm__step-3_mob.png";
import AlgorithmStep4Mob from "../../files/algorithm__step-4_mob.png";
import AlgorithmStep5Mob from "../../files/algorithm__step-5_mob.png";
import AlgorithmStep6Mob from "../../files/algorithm__step-6_mob.png";
import PlusesIconQuality from "../../files/pluses__icon-quality.png";
import PlusesIconSpeed from "../../files/pluses__icon-speed.png";
import PlusesIconQualityMob from '../../files/pluses__icon-quality_mob.png'
import PlusesIconSpeedMob from '../../files/pluses__icon-speed_mob.png';
import ServicesIconDev from "../../files/services__icon-dev.png";
import ServicesIconDesign from "../../files/services__icon-design.png";
import ServicesIconAds from "../../files/services__icon-ads.png";

export default function Offer() {
  const [windowWidth] = useWindowSize();

  return (
    <div className="block">
      {
        windowWidth > 1070 && 
        (
          <div className="block__content_column">
            <img alt="" className="offer-pg__clue-line" src={ClueLine} />
          </div>
        )
      }

      <div className="block__content_column">
        <h1>Нужен<br/>Сайт?</h1>

        <p className="text_body offer-pg__text_body">
          Мы разрабатываем сайты из готовых блоков в кратчайшие сроки. Вы сами выбираете структуру и дизайн, а остальное – за нами.
        </p>

        {windowWidth > 1070 ? (
          <img alt="" className="offer-pg__photo_pc" src={IntroPhotoPc} />
        ) : (
          <img alt="" className="offer-pg__photo_mob" src={IntroPhotoMob} />
        )}
      </div>

      {
        windowWidth <= 1070 && 
        (
          <div className="block__content_column">
            <img alt="" className="offer-pg__clue-line_mob" src={ClueLineMob} />
          </div>
        )
      }

      <div className="block__content_column">
        <div className="block__content_row"></div>
      </div>

      <div className="block__content_column offer-pg__block">
        <h3>Я хочу сайт. Что делать?</h3>

        <div className="block__content_column offer-pg__list offer-pg__algorithm-list">
          <div className="offer-pg__list-element">
            <div className="offer-pg__element-number">
              {windowWidth > 850 ? (
                <img src={AlgorithmStep1} />
              ) : (
                <img src={AlgorithmStep1Mob} />
              )}
            </div>

            <div className="offer-pg__element-desc">
              <h4 className="offer-pg__element-desc-title offer-pg__algorithm-step-title">
              расскажите о бизнесе
              </h4>

              <div className="offer-pg__element-dot convex">
                <div className="gradient-border"></div>
              </div>

              <p className="text_mini-body offer-pg__element-desc-text">
              Вы заполняете бриф и высказываете свои пожелания
              </p>

              {/* <NavLink
                className="button offer-pg__element-button"
                to="/blocks#examples"
              >
                <div className="gradient-border"></div>
                наши работы
              </NavLink> */}
            </div>
          </div>

          <div className="offer-pg__list-element">
            <div className="offer-pg__element-number">
              {windowWidth > 850 ? (
                <img src={AlgorithmStep2} />
              ) : (
                <img src={AlgorithmStep2Mob} />
              )}
            </div>

            <div className="offer-pg__element-desc">
              <h4 className="offer-pg__element-desc-title offer-pg__algorithm-step-title">
              мы предлагаем решение
              </h4>

              <div className="offer-pg__element-dot convex">
                <div className="gradient-border"></div>
              </div>

              <p className="text_mini-body offer-pg__element-desc-text">
              Мы подготавливаем макет и согласовываем его с вами
              </p>

              <NavLink className="button offer-pg__element-button" to="/blocks">
                <div className="gradient-border"></div>
                посмотреть блоки
              </NavLink>
            </div>
          </div>

          <div className="offer-pg__list-element">
            <div className="offer-pg__element-number">
              {windowWidth > 850 ? (
                <img src={AlgorithmStep3} />
              ) : (
                <img src={AlgorithmStep3Mob} />
              )}
            </div>

            <div className="offer-pg__element-desc">
              <h4 className="offer-pg__element-desc-title offer-pg__algorithm-step-title">
              наполняем макет контентом
              </h4>

              <div className="offer-pg__element-dot convex">
                <div className="gradient-border"></div>
              </div>

              <p className="text_mini-body offer-pg__element-desc-text">
              После согласования макета предоставьте материалы для сайта или закажите у нас
              </p>
            </div>
          </div>

          <div className="offer-pg__list-element">
            <div className="offer-pg__element-number">
              {windowWidth > 850 ? (
                <img src={AlgorithmStep4} />
              ) : (
                <img src={AlgorithmStep4Mob} />
              )}
            </div>

            <div className="offer-pg__element-desc">
              <h4 className="offer-pg__element-desc-title offer-pg__algorithm-step-title">
              финализируем макет
              </h4>

              <div className="offer-pg__element-dot convex">
                <div className="gradient-border"></div>
              </div>

              <p className="text_mini-body offer-pg__element-desc-text">
              Вы утверждаете итоговый дизайн, а мы вносим последние правки
              </p>
            </div>
          </div>

          <div className="offer-pg__list-element">
            <div className="offer-pg__element-number">
              {windowWidth > 850 ? (
                <img src={AlgorithmStep5} />
              ) : (
                <img src={AlgorithmStep5Mob} />
              )}
            </div>

            <div className="offer-pg__element-desc">
              <h4 className="offer-pg__element-desc-title offer-pg__algorithm-step-title">
              приступаем к разработке
              </h4>

              <div className="offer-pg__element-dot convex">
                <div className="gradient-border"></div>
              </div>

              <p className="text_mini-body offer-pg__element-desc-text">
              Разработка занимает от одного дня. Это зависит от сложности сайта
              </p>
            </div>
          </div>

          <div className="offer-pg__list-element">
            <div className="offer-pg__element-number">
              {windowWidth > 850 ? (
                <img src={AlgorithmStep6} />
              ) : (
                <img src={AlgorithmStep6Mob} />
              )}
            </div>

            <div className="offer-pg__element-desc">
              <h4 className="offer-pg__element-desc-title offer-pg__algorithm-step-title">
                готово!
              </h4>

              <div className="offer-pg__element-dot convex">
                <div className="gradient-border"></div>
              </div>

              {/* <p className="text_mini-body offer-pg__element-desc-text">
                Вы получаете свой новый сайт!
              </p> */}
            </div>
          </div>
        </div>

        <NavLink
          className="button button_biggest button_accent offer-pg__order-button"
          to="/order"
        >
          <div className="gradient-border"></div>
          заказать сайт
        </NavLink>
      </div>

      <div className="block__content_column offer-pg__block offer-pg__advantages">
        <h3>Мы работаем:</h3>

        <div className="block__content_column offer-pg__list offer-pg__list-advantages">
          <div className="offer-pg__list-element">
            <div className="offer-pg__element-icon">
              {windowWidth > 850 ? (
                <img
                  className="offer-pg__quality-icon"
                  src={PlusesIconQuality}
                />
              ) : (
                <div className="offer-pg__quality-icon-wrapper_mob convex_smaller">
                  <div className="gradient-border"></div>
                  <img className="offer-pg__quality-icon_mob" src={PlusesIconQualityMob} />
                </div>
              )}
            </div>

            <div className="offer-pg__element-desc offer-pg__element-desc_quality">
              <h4 className="offer-pg__element-desc-title">качественно</h4>

              <p className="text_mini-body offer-pg__element-desc-text">
              Мы заранее написали код и умеем быстро адаптировать его под ваши потребности. Это значит, что сайт будет работать сразу и без ошибок.
              </p>
            </div>
          </div>

          <div className="offer-pg__list-element">
            <div className="offer-pg__element-icon">
              {windowWidth > 850 ? (
                <img
                  className="offer-pg__speed-icon"
                  src={PlusesIconSpeed}
                />
              ) : (
                <div className="offer-pg__quality-icon-wrapper_mob convex_smaller">
                  <div className="gradient-border"></div>
                  <img className="offer-pg__speed-icon_mob" src={PlusesIconSpeedMob} />
                </div>
              )}
            </div>

            <div className="offer-pg__element-desc offer-pg__element-desc_speed">
              <h4 className="offer-pg__element-desc-title">быстро</h4>

              <p className="text_mini-body offer-pg__element-desc-text">
              Мы собираем сайты из готовых дизайн-блоков. Если у вас есть контент и вы знаете, чего хотите, ваш сайт  может быть разработан в течение суток.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="block__content_column offer-pg__block offer-pg__services">
        <h3 className="offer-pg__h2">дополнительные услуги</h3>

        <div className="block__content_column offer-pg__list">
          <div className="offer-pg__list-element offer-pg__additional-service">
            <div className="offer-pg__element-icon offer-pg__add-service-icon convex_smaller">
              <div className="gradient-border"></div>

              <img className="offer-pg__custom-dev-icon" src={ServicesIconDev} />
            </div>

            <div className="offer-pg__element-desc">
              <h4 className="offer-pg__element-desc-title">
                кастомная разработка
              </h4>

              <p className="text_mini-body offer-pg__element-desc-text">
              Мы разработаем сайт любой сложности. Индивидуальный проект дороже и занимает больше времени.
              </p>
            </div>
          </div>

          <div className="offer-pg__list-element offer-pg__additional-service">
            <div className="offer-pg__element-icon offer-pg__add-service-icon convex_smaller">
              <div className="gradient-border"></div>

              <img className="offer-pg__custom-des-icon" src={ServicesIconDesign} />
            </div>

            <div className="offer-pg__element-desc">
              <h4 className="offer-pg__element-desc-title">контент</h4>

              <p className="text_mini-body offer-pg__element-desc-text">
              Мы предоставляем услуги по созданию любого контента. Нужны тексты на сайт, баннеры, шаблоны для соцсетей или новый логотип? Мы поможем.
              </p>
            </div>
          </div>

          <div className="offer-pg__list-element offer-pg__additional-service">
            <div className="offer-pg__element-icon offer-pg__add-service-icon convex_smaller">
              <div className="gradient-border"></div>

              <img className="offer-pg__ads-icon" src={ServicesIconAds} />
            </div>

            <div className="offer-pg__element-desc">
              <h4 className="offer-pg__element-desc-title">
              настройка контекстной рекламы
              </h4>

              <p className="text_mini-body offer-pg__element-desc-text">
              Мы настраиваем рекламу в Яндекс.Директ и Google Adwords, чтобы ваш сайт сразу начал привлекать клиентов.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
